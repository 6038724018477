<template>
  <div class="p-3">
    <checkAcademicQualificationCertificate
      :propsData1="getUserFromId"
      :propsData2="getAcademicQualification"
    ></checkAcademicQualificationCertificate>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import checkAcademicQualificationCertificate from "../../components/checkAcademicQualification/checkAcademicQualificationCertificate.vue";
import * as encodeDecodeService from "../../services/encodeDecode.service";
export default {
  name: "CheckAcademicQualification",

  components: {
    checkAcademicQualificationCertificate,
  },
  data() {
    return {
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
      productId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
    };
  },
  computed: {
    ...mapGetters(["getAcademicQualification", "getUserFromId"]),
  },
  methods: {
    ...mapActions(["fetchAcademicQualification", "fetchUserFromId"]),
  },
  created() {
    this.fetchUserFromId(this.userId);
    this.fetchAcademicQualification(this.projectId);
  },
};
</script>
